import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  general_dropdown = document.getElementById('general-dropdown')
  game_dropdown = document.getElementById('game-dropdown')
  general_open = false
  game_open = false
  
  connect() {
    console.log("Hello Navigation2!")
  }

  // TODO: Figure out a way remove the flicker of swaping between nav menus
  generalToggle() {
    if(this.game_open) {
      this.closeGame()
    }
    if(this.general_open) {
      this.closeGeneral()
    } else {
      this.general_dropdown.classList.remove('opacity-0')
      this.general_dropdown.classList.remove('-translate-y-1')
      this.general_dropdown.classList.remove('invisible')
      this.general_dropdown.classList.add('visible')
      this.general_dropdown.classList.add('opacity-100')
      this.general_dropdown.classList.add('translate-y-0')
      this.general_open = true
    }
  }

  gameToggle() {
    if(this.general_open) {
      this.closeGeneral()
    }
    if(this.game_open) {
      this.closeGame()
    } else {
      this.game_dropdown.classList.remove('opacity-0')
      this.game_dropdown.classList.remove('-translate-y-1')
      this.game_dropdown.classList.remove('invisible')
      this.game_dropdown.classList.add('visible')
      this.game_dropdown.classList.add('opacity-100')
      this.game_dropdown.classList.add('translate-y-0')
      this.game_open = true
    }
  }

  closeGeneral() {
    this.general_dropdown.classList.remove('opacity-100')
    this.general_dropdown.classList.remove('translate-y-0')
    this.general_dropdown.classList.add('opacity-0')
    this.general_dropdown.classList.add('-translate-y-1')
    this.general_dropdown.classList.add('invisible')
    this.general_open = false
  }

  closeGame() {
    this.game_dropdown.classList.remove('opacity-100')
    this.game_dropdown.classList.remove('translate-y-0')
    this.game_dropdown.classList.add('opacity-0')
    this.game_dropdown.classList.add('-translate-y-1')
    this.game_dropdown.classList.add('invisible')
    this.game_open = false
  }
}



// Entering: "transition ease-out duration-200"
// From: "opacity-0 -translate-y-1"
// To: "opacity-100 translate-y-0"
// Leaving: "transition ease-in duration-150"
// From: "opacity-100 translate-y-0"
// To: "opacity-0 -translate-y-1"