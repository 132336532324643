import { Controller } from "@hotwired/stimulus"
window.Tablesort = require('tablesort');
require('tablesort/src/sorts/tablesort.number');

export default class extends Controller {
  connect() {
    new Tablesort(this.element, {
      descending: true
    })
  }

  // SPECIFIC ACTIONS
  characterLoad() {
    window.scrollTo({top: 0, behavior: 'smooth'});
  }
}
