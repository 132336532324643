import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ 
    'basicPlaylog', 'basicTab', 
    'advancedPlaylog', 'advancedTab',
    'expertPlaylog', 'expertTab',
    'masterPlaylog', 'masterTab',
    'worlds_endPlaylog', 'worlds_endTab',
    'world_recordPlaylog', 'world_recordTab',
    'playlog']
  static values = { visible: String }

  tabClick() {
    var difficulty = event.target.getAttribute('data-difficulty')
    
    if(difficulty != this.visibleValue) {
      this[this.visibleValue + 'PlaylogTarget'].classList.add('hidden')
      this[difficulty + 'PlaylogTarget'].classList.remove('hidden')

      this[this.visibleValue + 'TabTarget'].parentElement.classList.remove('border-gray-200')
      this[this.visibleValue + 'TabTarget'].parentElement.classList.add('border-transparent')

      this[difficulty + 'TabTarget'].parentElement.classList.remove('border-transparent')
      this[difficulty + 'TabTarget'].parentElement.classList.add('border-gray-200')
    }
    this.visibleValue = difficulty
  }
}