import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    console.log("Hello from the Search Controller")
  }

  search() {
    this.element.requestSubmit()
  }

  toggleAdvancedDocumentation() {
    var adv = document.getElementById('advanced_documentation')
    if(adv.classList.contains('hidden')) {
      adv.classList.remove('hidden')
    } else {
      adv.classList.add('hidden')
    }
    console.log("toggle advanced")
  }

  // enter_submit(event) {
  //   event.preventDefault();
  //   if (event.keyCode == 13) {
  //     console.log(this.queryTarget.value)
  //     this.submit();
  //     //window.location = window.location.pathname + "?q=" + this.queryTarget.value
  //   }
  // }
}